import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'

import '../components/base.css'
import Layout from '../components/Layout'
import { TermsOfServiceQuery } from '../../graphql-types'
import DocumentContent from '../components/DocumentContent'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'

const TermsOfService: FC<PageProps<TermsOfServiceQuery>> = ({ data }) => {
  const { meta, footer } = extractGlobals(data)

  return (
    <Layout tabNames={[]} meta={meta} footer={footer}>
      <DocumentContent
        title={data.prismicTermsOfService?.data?.title?.text}
        content={data.prismicTermsOfService?.data?.content?.html}
      />
    </Layout>
  )
}

export default withPreview(TermsOfService)

export const pageQuery = graphql`
  query TermsOfService {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    prismicTermsOfService {
      data {
        title {
          text
        }
        content {
          html
          raw
        }
      }
    }
  }
`
